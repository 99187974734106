import React from 'react';

const AnimatedSVG = () => {
  return (
    <div>
      {/* <style>
        {`
          #path1, #path2 {
            stroke-dasharray: 2000;
            stroke-dashoffset: 2000;
            animation: draw 4s forwards infinite;
          }

          #path2 {
            animation-delay: 2s;
          }

          @keyframes draw {
            to {
              stroke-dashoffset: 0;
            }
          }
        `}
      </style> */}
      <svg xmlns="http://www.w3.org/2000/svg" width="200" height="22" viewBox="0 0 256 22" fill="none">
        <path id="path1" d="M2.08161 10.0014C2.08161 10.0014 61.9786 3.88266 100.718 3.98988C160.886 4.15641 253.804 19.5733 253.804 19.5733" stroke="#FF9000" strokeWidth="3" strokeLinecap="round"/>
        <path id="path2" d="M34.3461 12.3714C34.3461 12.3714 51.6577 10.1616 80.9661 9.63701C126.486 8.82226 171.407 18.5848 171.407 18.5848" stroke="#FF9000" strokeWidth="3" strokeLinecap="round"/>
      </svg>
    </div>
  );
};

export default AnimatedSVG;