import React, { useRef } from "react";
import MobileScreenshot from "../../assets/icons/mobile_screenshot.svg"
import AnimatedSVG from "../../assets/icons/AnimatedSvg"
import HeroSvg1 from "../../assets/icons/landing/hero1.svg"
import HeroSvg2 from "../../assets/icons/landing/hero2.svg"
import HeroSvg3 from "../../assets/icons/landing/hero3.svg"
import HeroSvg4 from "../../assets/icons/landing/hero4.svg"
import HeroSvg5 from "../../assets/icons/landing/hero5.svg"
import { TypeAnimation } from 'react-type-animation';
import Tilts from "components/widget/Tilt";

interface Props {

}

const HeroTop: React.FC<Props> = (props: Props) => {
    const divRef = useRef(null);
    // const blendBall = useRef(null);

//     useEffect(() => {
//         document.body.addEventListener('mousemove', handleMouseMove)
//         const $hoverables = document.querySelectorAll(".hoverable_index");


//         for (let i = 0; i < $hoverables.length; i++) {
//             $hoverables[i].addEventListener("mouseenter", handleMouseEnter);
//             $hoverables[i].addEventListener("mouseleave", onMouseHoverOut);
//         }
//     }, [])

//     const handleMouseMove = (e: any) => {
//         if (divRef.current) {
//             // Get the bounding rectangle of the div

//             const x = e.pageX;
//             const y = e.pageY;


//             gsap.to(blendBall.current, {
//                 duration: 0.7,
//                 left: x - 150 - 15, // Adjusting cursor position to center the cursor
//                 top: y - 150
//             });

//         }
//     }

//     const handleMouseEnter = (e: any) => {
//             gsap.to(blendBall.current, {
//                 duration: 1,
//                 scale: 1
//             });
//     }
//     const onMouseHoverOut = (e: any) => {
//         gsap.to(blendBall.current, {
//             duration: 0.7,
//             scale: 0.05
//         });
// }

    return (
        <div className="px-3 pt-3 relative flex justify-center">
            <Tilts className="top-[10%] left-[10%] absolute lg:block hidden">
                <img alt="" src={HeroSvg1} />
            </Tilts>
            <Tilts className="top-[3%] right-[8%] absolute" >
                <img alt="" src={HeroSvg2} />
            </Tilts>
            <Tilts className="bottom-[calc(60%-150px)] w-[130px] left-0 absolute sm:block hidden">
                <img alt="" src={HeroSvg3} />
            </Tilts>
            <Tilts className="bottom-[calc(60%-150px)] w-[100px] right-0 absolute sm:block hidden">
                <img alt="" src={HeroSvg4} />
            </Tilts>
            <Tilts className="top-[40px] w-[60px] left-[calc(50%-50px)] absolute md:block hidden">
                <img alt="" src={HeroSvg5} />
            </Tilts>

            <div className="flex justify-center mt-28 flex-wrap max-w-[1500px]">
                <div className="lg:w-6/12 md:w-8/12 max-w-[500px] text-center  relative">
                    <h1 ref={divRef} className="hero_header relative hoverable_index text-grocipe_dark_green">
                        {/* <div ref={blendBall} className="blend_mode"></div> */}
                        Transform Your Cooking with AI Magic!
                        <span className="float_lines">
                            <AnimatedSVG />
                        </span>
                    </h1>
                    <p className="hero_desc">Discover how AI can help find recipes, substitute ingredients, and use up those random groceries left in your
                        <TypeAnimation
                            sequence={[
                                // Same substring at the start will only be typed once, initially
                                'fridge.',
                                3000,
                                'store.',
                                3000,
                                'shelves.',
                                3000,
                            ]}
                            speed={50}
                            style={{ fontSize: 'inherit', marginLeft: '5px' }}
                            repeat={Infinity}
                        />
                    </p>
                    <div className="flex justify-center my-5 ">
                        <a className="anchor" target="blanck" href="https://docs.google.com/forms/d/e/1FAIpQLSc3ncJFa0QMe5HSPFzSj2NQRokNrYsuVjI1KMYcP7JdfxST4A/viewform">
                            <button className="waitlist_button draw_border">Join waitlist</button>
                        </a>
                    </div>
                </div>
                <div className="flex justify-center">
                    <img alt="" src={MobileScreenshot} className="w-[90%]" />
                </div>
            </div>
        </div>
    );
};

export default HeroTop;
