import React from "react";
import { useInView, animated } from '@react-spring/web'
import ComingSoonIos from "../../assets/icons/coming_soon.svg"
import ComingSoonAndroid from "../../assets/icons/coming_soon_android.svg"
import ComingSoonSvg from "../../assets/icons/comingSoon.svg"


interface Props {

}

const ComingSoon: React.FC<Props> = (props: Props) => {
    const [ref, springs] = useInView(
        () => ({
            from: {
                opacity: 0,
                y: 10,
            },
            to: {
                opacity: 1,
                y: 0,
            },
        }),
        {
            rootMargin: '-40% 0%',
            once: true,
        }
    )

    const [desc, springs_2] = useInView(
        () => ({
            from: {
                opacity: 0,
                y: 50,
            },
            to: {
                opacity: 1,
                y: 0,
            },
        }),
        {
            rootMargin: '-40% 0%',
            once: true,
        }
    )

    const [title, springs_3] = useInView(
        () => ({
            from: {
                opacity: 0,
                y: 100,
            },
            to: {
                opacity: 1,
                y: 0,
            },
        }),
        {
            rootMargin: '-40% 0%',
            once: true,
        }
    )

    return (
        <div className="connect_hero_container w-full">

            <div className="flex justify-center mt-0 lg:pt-12 ">
                <div className="lg:w-10/12 md:w-11/12 flex max-w-[1500px] flex-wrap items-center">
                    <div className="w-full lg:w-6/12 flex justify-center">
                        <img alt="" src={ComingSoonSvg} />
                    </div>
                    <div className="w-full lg:w-6/12 mt-6 mb-4">
                        <b className="texts-base text-grocipe_green">Download</b>
                        <animated.div ref={ref} style={springs}>
                            <h1 className="about_header mt-2 pt-0">Transform Your Grocery Shopping</h1>
                        </animated.div>
                        <animated.div ref={title} style={springs_3}>
                            <p className="about_desc">Generate a shopping list based on your chosen recipes and meal plans. Ensure you never forget an ingredient and make your grocery trips more efficient and organized.</p>
                        </animated.div>
                        <animated.div ref={desc} style={springs_2}>
                            <div>
                                <div className="pt-4">
                                    <button className="ios_coming_soon ml-0"><img alt="" className="cmt_img" src={ComingSoonIos} /></button>
                                    <button className="ios_coming_soon"><img alt="" className="cmt_img" src={ComingSoonAndroid} /></button>
                                </div>
                            </div>
                        </animated.div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ComingSoon;
