import React from "react";
import HomeCooking from "../../assets/icons/home_cooking.svg"
import { useInView, animated } from '@react-spring/web'
import AboutIcon1 from "../../assets/icons/landing/about_icon_1.svg"
import AboutIcon2 from "../../assets/icons/landing/about_icon_2.svg"
import AboutIcon3 from "../../assets/icons/landing/about_icon_3.svg"
import Tilt from "components/widget/Tilt";


interface Props {

}

const MidHero: React.FC<Props> = (props: Props) => {
    const [ref, springs] = useInView(
        () => ({
            from: {
                opacity: 0,
                y: 10,
            },
            to: {
                opacity: 1,
                y: 0,
            },
        }),
        {
            rootMargin: '-40% 0%',
            once: true,
        }
    )

    const [desc, springs_2] = useInView(
        () => ({
            from: {
                opacity: 0,
                y: 50,
            },
            to: {
                opacity: 1,
                y: 0,
            },
        }),
        {
            rootMargin: '-40% 0%',
            once: true,
        }
    )

    const [title, springs_3] = useInView(
        () => ({
            from: {
                opacity: 0,
                y: 100,
            },
            to: {
                opacity: 1,
                y: 0,
            },
        }),
        {
            rootMargin: '-40% 0%',
            once: true,
        }
    )

    return (
        <div className="mid_hero_container hero_top_layer relative md:pb-[100px]">
            <Tilt className="absolute top-10 right-0 w-32 hidden md:block">
                <img alt="" src={AboutIcon1} />
            </Tilt>
            <Tilt className="absolute bottom-10 left-0 w-36 hidden md:block">
                <img alt="" src={AboutIcon2} />
            </Tilt>
            <Tilt className="absolute bottom-[10px] left-[calc(50%-80px)] w-36 hidden lg:block">
                <img alt="" src={AboutIcon3} />
            </Tilt>


            <div className="flex justify-center mt-0 lg:pb-20">
                <div className="lg:w-10/12 md:w-11/12 flex max-w-[1500px] flex-wrap items-center">
                    <div className="w-full lg:w-6/12 mt-6 mb-4">
                        <p>About Us</p>
                        <animated.div ref={ref} style={springs}>
                            <h1 className="about_header">Revolutionizing Home Cooking</h1>
                        </animated.div>
                        <animated.div ref={title} style={springs_3}>
                            <p className="about_desc">Grocipie AI is dedicated to transforming your cooking experience with the power of artificial intelligence. Say goodbye to meal planning stress and hello to culinary creativity.</p>
                        </animated.div>
                        <animated.div ref={desc} style={springs_2}>
                            <button className="waitlist_button draw_border">Get Started</button>
                        </animated.div>
                    </div>
                    <div className="w-full lg:w-6/12 flex justify-center">
                        <img alt="" src={HomeCooking} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MidHero;
