import React from "react";
import CurlDelightMobileImage from "../../assets/icons/curl_delight_mobile_image.svg"
import Ball_breen from "../../assets/icons/Rectangle_ball.png"
import { animated, useInView } from "@react-spring/web";
import CoffeeDiscover from "../../assets/icons/landing/coffee_discover.svg"
import MealPlaning from "../../assets/icons/landing/meal_planing.svg"
import StreamlineShop from "../../assets/icons/landing/streamline_shop.svg"
import SimpleMeal from "../../assets/icons/landing/simpleMeal.svg"
import StreamGrocery from "../../assets/icons/landing/StreamGrocery.svg"
import Tilts from "components/widget/Tilt";

interface Props {

}

const springProps = {
    from: {
        width: 0
    },
    to: {
        width: 600,
    },
    config: {
        duration: 300,
    },
}

const springConfig = {
    rootMargin: '-45% 0px -45% 0px',
}

const springTextProps = {
    from: {
        opacity: 0,
        y: 80,
    },
    to: {
        opacity: 1,
        y: 0,
    },
    config: {
        duration: 400,
    },
}

const springTextConfig = {
    rootMargin: '-40% 0%',
    once: true,
}

const springsideTextProps = {
    from: {
        opacity: 0,
        x: 80,
    },
    to: {
        opacity: 1,
        x: 0,
    },
    config: {
        duration: 400,
    },
}
const springslideTextProps = {
    from: {
        opacity: 0,
        x: -80,
    },
    to: {
        opacity: 1,
        x: 0,
    },
    config: {
        duration: 400,
    },
}


const FeaturesComponent: React.FC<Props> = (props: Props) => {
    const [ref, springs] = useInView(
        () => (springProps),
        springConfig
    )

    const [ref2, springs2] = useInView(
        () => (springProps),
        springConfig
    )

    const [ref3, springs3] = useInView(
        () => (springProps),
        springConfig
    )

    const [ref4, springs4] = useInView(
        () => (springTextProps),
        springTextConfig
    )

    const [ref5, springs5] = useInView(
        () => (springTextProps),
        springTextConfig
    )

    const [ref6, springs6] = useInView(
        () => (springTextProps),
        springTextConfig
    )
    const [ref7, springs7] = useInView(
        () => (springsideTextProps),
        springTextConfig
    )

    const [ref8, springs8] = useInView(
        () => (springsideTextProps),
        springTextConfig
    )

    const [ref9, springs9] = useInView(
        () => (springsideTextProps),
        springTextConfig
    )

    const [ref10, springs10] = useInView(
        () => (springslideTextProps),
        springTextConfig
    )

    const [ref11, springs11] = useInView(
        () => (springslideTextProps),
        springTextConfig
    )

    const [ref12, springs12] = useInView(
        () => (springslideTextProps),
        springTextConfig
    )


    return (
        <div className="connect_hero_container w-full">
            <div className="flex justify-center mt-0 flex-wrap">
                <div className=" w-full flex items-center justify-center relative">
                    <img alt="" src={Ball_breen} className="float_ball_green" />
                    <div className="flex  flex-wrap max-w-[1500px] items-center">
                        <div className="curl_container_1 lg:order-1 order-2">
                            <animated.div ref={ref} className="bg_layer_underlay" style={springs}>
                            </animated.div>
                            <img alt="" src={CurlDelightMobileImage} className="z-10" />
                            <Tilts className="absolute bottom-[calc(30%+0px)] left-10 hidden sm:block" >
                                <img alt="" src={CoffeeDiscover} />
                            </Tilts>
                        </div>
                        <div className="curl_container_2 lg:order-2 order-1">
                            <div className="w-11/12 lg:w-10/12">
                                <animated.div ref={ref10} style={springs10}>
                                    <p className="feature_name">Smart Recipe Suggestions</p>
                                </animated.div>
                                <animated.div ref={ref11} style={springs11}>
                                    <h1 className="about_header linear_wipe">Discover New Culinary Delights</h1>
                                </animated.div>
                                <animated.div ref={ref12} style={springs12}>
                                    <p className="about_desc">
                                        Enter the ingredients you have, and let our AI suggest a variety of delicious recipes. Never run out of meal ideas and always find something exciting to cook with what’s already in your kitchen.
                                    </p>
                                </animated.div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex w-full flex-wrap items-center justify-center relative">
                    <div className="flex max-w-[1500px] flex-wrap items-center">
                        <div className="curl_container_br  flex justify-center">
                            <div className="lg:w-9/12 md:w-11/12 w-full">
                                <animated.div ref={ref7} style={springs7}>
                                    <p className="feature_name">Meal Planning</p>
                                </animated.div>
                                <animated.div ref={ref8} style={springs8}>
                                    <h1 className="about_header linear_wipe">Simplify Your Meal Planning</h1>
                                </animated.div>
                                <animated.div ref={ref9} style={springs9}>
                                    <p className="about_desc">
                                        Take the guesswork out of meal preparation with personalized meal plans tailored to your dietary preferences and schedule. Plan your meals for the week, save time, and enjoy balanced, nutritious dishes every day.
                                    </p>
                                </animated.div>
                            </div>
                        </div>
                        <div className="curl_container_sr relative">
                            <animated.div ref={ref2} className="bg_layer_sidelay" style={springs2}>
                            </animated.div>
                            <img alt="" src={SimpleMeal} className="z-10" />
                            <img alt="" src={MealPlaning} className="absolute bottom-[calc(30%+0px)] right-0 w-36  hidden sm:block" />
                        </div>
                    </div>
                </div>

                <div className="flex  flex-wrap items-center justify-center relative">
                    <div className="curl_container_2">
                        <div className="w-11/12 lg:w-10/12">
                            <animated.div ref={ref4} style={springs4}>
                                <p className="feature_name">Shopping List</p>
                            </animated.div>
                            <animated.div ref={ref5} style={springs5}>
                                <h1 className="about_header linear_wipe">Streamline Your Grocery Shopping</h1>
                            </animated.div>
                            <animated.div ref={ref6} style={springs6}>
                                <p className="about_desc">
                                    Aenerate a shopping list based on your chosen recipes and meal plans. Ensure you never forget an ingredient and make your grocery trips more efficient and organized.
                                </p>
                            </animated.div>
                        </div>
                    </div>

                    <div className="curl_container_1">
                        <animated.div ref={ref3} className="bg_layer_underlay" style={springs3}>
                        </animated.div>
                        <img alt="" src={StreamGrocery} className="z-10" />
                        <img alt="" src={StreamlineShop} className="absolute bottom-[calc(35%+0px)] left-0 w-28  hidden sm:block" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeaturesComponent;
