import React from 'react';
import "./assets/styles/main.scss"
import RootRoutes from './navigation';


function App() {
  return (
      <RootRoutes/>
  );
}

export default App;
