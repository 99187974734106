import React from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import route from "./router";
import stack from "./stack";
import NotFound from "components/widget/404";



const RootRoutes = () => {
  const landing = route.filter((item, index) => {
    return item?.stack === stack.LANDING || item?.stack === stack.APP;
  });

  // check if user is already saved to storage



  return (
    <BrowserRouter>
      <Routes>
        {
        landing.map((item, index: number) => {
          const { Page, route } = item;
          return <Route key={index} path={route} element={<Page />} />;
        })
        }
        <Route path='*' element={<NotFound />}/>
      </Routes>
    </BrowserRouter>
  );
};

export default RootRoutes;
