import React from "react";
import connect_text from "../../assets/icons/connect_text.svg"

interface Props {

}

const ConnectUs: React.FC<Props> = (props: Props) => {

    return (
        <div className="connect_hero_container_2 mt-24">
            <div className="flex justify-center -mt-12 connect_bg">
                <img alt="" src={connect_text} className="float_text_connect" />
                <div className="connect_main_wrapper max-w-[1500px]">
                    <div className="connect_wrapper">
                        <h1 className="connect_with_us">Connect with us</h1>
                    </div>
                    <div className="connect_wrapper connect_info sm:justify-center flex">
                        <div className="sm:w-10/12">
                            <b className="connect_bold_title">Email :</b>
                            <p>info@gorcipe.com</p>
                        </div>
                    </div>
                    <div className="connect_wrapper  connect_info">
                        <b className="connect_bold_title">Address :</b>
                        <p>No 1, Jermy’s close,Off Commercial Avenue, Chicago, NY, USA</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConnectUs;
