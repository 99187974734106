import React, { useState } from "react";
import Applogo from '../../assets/icons/Applogo.svg';
import hamburdger from "../../assets/icons/hamburder.svg"

interface Props {
    moveToSections: Function,
    navOpacity: number
}


const TopNav: React.FC<Props> = (props: Props) => {
    const [dropDown, setDropDown] = useState<boolean>(true)
    const { moveToSections, navOpacity } = props


    return (
        <div className="px-3 pt-3 lg:px-20 fixed top-0 left-0 p-4 w-full z-[100] transition duration-300 backdrop-blur-lg"
            style={{ backgroundColor: `rgba(255,255,255,${navOpacity < 0.01 && dropDown ? 0 : 0.7})` }}
        >
            <div className="nav_top_container">
                <div className="logo_container">
                    <img alt="" src={Applogo} />
                    <button
                        onClick={() => setDropDown(!dropDown)}
                    >
                        <img alt="" src={hamburdger} />
                    </button>
                </div>
                <div className={`responsive_nav ${dropDown ? 'max_h_0' : 'max_h_screen'}`}>
                    <div className="links_container">
                        <button
                            onClick={() => moveToSections('home')}
                        >home</button>
                        <button
                            onClick={() => moveToSections('about_us')}
                        >About us</button>
                        <button
                            onClick={() => moveToSections('features')}
                        >Features</button>
                        <button
                            onClick={() => moveToSections('faq')}
                        >FAQ</button>
                        <button
                            onClick={() => moveToSections('contact_us')}
                        >Contact us </button>
                    </div>
                    <div className="waitlist_container">
                        <a className="anchor" target="blanck" href="https://docs.google.com/forms/d/e/1FAIpQLSc3ncJFa0QMe5HSPFzSj2NQRokNrYsuVjI1KMYcP7JdfxST4A/viewform">
                            <button className="waitlist_container_button draw_border">Join waitlist</button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopNav;
