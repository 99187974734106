import React from "react";
import Oval from "../../assets/icons/Oval.svg"

interface Props {

}

const ContactUs: React.FC<Props> = (props: Props) => {

    return (
        <div className="mid_hero_container">
            <img src={Oval} alt="" className="absolute top-[30%] w-[400px] hidden sm:block" />
            <div className="flex justify-center mt-24 z-[20] relative">
                <div className="w-full justify-center flex">
                    <div className="contact_main_wrapper max-w-[1500px]">
                        <div className="contact_us_container">
                            <h1 className="contact_header">
                                Join our new letter to receive latest updates on grocipe AI
                            </h1>
                            <div className="lg:w-9/12 w-full">
                                <input className="input_info" placeholder="Enter Email" />
                                <div className="w-full flex justify-center">
                                    <button className="subscribe_btn">Subscribe</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
