import React from "react";
import FaqCollapse from "components/widget/faqCollapse";

interface Props {

}

const Faq: React.FC<Props> = (props: Props) => {

    return (
        <div className="connect_hero_container mt-24">
            <div className="flex justify-center mt-0">
                <div className="lg:w-10/12 md:w-11/12 max-w-[1500px] flex flex-wrap ">
                    <div className="w-full lg:w-5/12">
                        <h1 className="about_header">Frequently Asked Questions</h1>
                    </div>
                    <div className="w-full lg:w-7/12 px-2 lg:px-12">
                        <FaqCollapse defaultValue={true} title={"How can the AI chat feature help me?"}>
                            <p>The AI chat function can help you search recipes, save recipes, and add them
                                 to your meal plan all from the same page. No more shuffling between web pages 
                                 or handwritten notes.</p>
                        </FaqCollapse>
                        <FaqCollapse title={"What if I have dietary restrictions?"}>
                            <p>The AI chat function can help you search recipes, save recipes, and add them
                                 to your meal plan all from the same page. No more shuffling between web pages 
                                 or handwritten notes.</p>
                        </FaqCollapse>
                        <FaqCollapse title={"Can I share recipes or meal plans with friends and family?"}>
                            <p>The AI chat function can help you search recipes, save recipes, and add them
                                 to your meal plan all from the same page. No more shuffling between web pages 
                                 or handwritten notes.</p>
                        </FaqCollapse>
                        <FaqCollapse title={"Can I Import Other Recipes For Different Sources?"}>
                            <p>The AI chat function can help you search recipes, save recipes, and add them
                                 to your meal plan all from the same page. No more shuffling between web pages 
                                 or handwritten notes.</p>
                        </FaqCollapse>
                        <FaqCollapse title={"How do I generate a grocery list from my meal plan?"}>
                            <p>The AI chat function can help you search recipes, save recipes, and add them
                                 to your meal plan all from the same page. No more shuffling between web pages 
                                 or handwritten notes.</p>
                        </FaqCollapse>
                        <FaqCollapse title={"Can I track nutritional info for my meals?"}>
                            <p>The AI chat function can help you search recipes, save recipes, and add them
                                 to your meal plan all from the same page. No more shuffling between web pages 
                                 or handwritten notes.</p>
                        </FaqCollapse>
                        
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Faq;
