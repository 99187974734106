import React from "react";
import Quote from "../../assets/icons/quote.svg"
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import './styles.css';

interface Props {

}

const Testimonia: React.FC<Props> = (props: Props) => {

    return (
        <div className="mid_hero_container mt-12">
            <div className="flex justify-center mt-12">
                <div className="lg:w-10/12 md:w-11/12 w-full max-w-[1500px] flex flex-wrap items-center">
                    <b className="feature_name block w-full">Testimonials</b>
                    <h1 className="about_header w-full lg:w-5/12 capitalize">What our Users are saying about us</h1>
                    <div className="w-full mt-5 overflow-x-scroll">
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={10}
                            loop
                            pagination={{
                                clickable: true,
                            }}
                            breakpoints={{
                                640: {
                                    slidesPerView: 2,
                                    spaceBetween: 40,
                                },
                                768: {
                                    slidesPerView: 4,
                                    spaceBetween: 40,
                                },
                                1024: {
                                    slidesPerView: 4,
                                    spaceBetween: 40,
                                },
                            }}
                            className="mySwiper"
                        >
                            {/* <div className="scoller_right"> */}
                            {
                                [1, 2, 3, 4, 5].map((item, index) => {
                                    return (
                                        <SwiperSlide>
                                            <div key={index + 'testimonia'} className="testimonia_box">
                                                <p className="mb-2"><img alt="" src={Quote} /></p>
                                                <p className="whitespace-normal">Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Suspendisse varius enim in eros elementum tristique.
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Suspendisse varius enim in eros elementum tristique.
                                                </p>
                                                <div className="testimonia_info">
                                                    <div>
                                                        <b className="name_avater">A</b>
                                                        <p>
                                                            <b className="block w-full">Annie January</b>
                                                            <span>banker</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    )
                                })
                            }


                        </Swiper>
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Testimonia;
