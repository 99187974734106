import React from "react";
import Applogo from '../../assets/icons/Applogo.svg';
import TwitterIcon from "../../assets/icons/twitter_icon.svg"
import Tweet from "../../assets/icons/tweet.svg"
import InstaGram from "../../assets/icons/instagram_icon.svg"

interface Props {
    moveToSections: Function
}

const Footer: React.FC<Props> = (props: Props) => {
    const { moveToSections } = props
    return (
        <div className="connect_footer_container">
            <div className="flex justify-center">
                <div className="w-full flex flex-wrap justify-center text-sm text-white">
                    <div className="flex w-full max-w-[1500px] px-3 justify-center flex-wrap my-5">
                        <div className="w-full md:w-6/12 lg:w-7/12 mb-4">
                            <img alt="" src={Applogo} />
                        </div>
                        <div className="w-full md:w-5/12 lg:w-4/12 flex">
                            <div className="w-6/12 footer_links">
                                <b>Company</b>
                                <span className="cursor-pointer" onClick={() => moveToSections('about_us')}>About Us</span>
                                <span className="cursor-pointer" onClick={() => moveToSections('features')}>Features</span>
                                <span className="cursor-pointer" onClick={() => moveToSections('faq')}>FAQ</span>
                            </div>
                            <div className="w-6/12 footer_links">
                                <b>Legal</b>
                                <span>Terms & Conditions</span>
                                <span>Privacy Policy</span>
                            </div>
                        </div>
                    </div>
                    <div className="reserve_context">
                        <p className="flex-1 px-3 lg:px-16 my-2">
                            Copyright@2024, All rights reserved.
                        </p>
                        <p className="my-2 w-full sm:w-auto px-3 sm:px-0 flex items-center mr-8 lg:mr-16">
                            <span>Follow Us : </span>
                            <a target="blanck" href="https://x.com/Grocipe_app">
                                <button><img alt="" className="w-5 mx-3" src={TwitterIcon} /></button>
                            </a>
                            <a href="https://x.com/Grocipe_app" target="blanck">
                                <button><img alt="" className="w-5 mx-3" src={Tweet} /></button>
                            </a>
                            <a target="blanck" href="https://x.com/Grocipe_app">
                            <button><img alt="" className="w-5 mx-3" src={InstaGram} /></button>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
