import React, { ReactNode, useEffect, useState } from 'react'
import ChevronDown from "../../assets/icons/chevron_down.svg"

interface Props { 
    children: ReactNode,
    title: string,
    defaultValue?: boolean
}

function FaqCollapse(props: Props) {
    const { children, title, defaultValue } = props
    const [open, setOpen] = useState<boolean | undefined>(true)

    useEffect(()=>{
       defaultValue !== undefined && setOpen(defaultValue)
    }, [defaultValue])

    return (
        <div className="w-full mb-6">
            <p className="w-full flex items-center mb-5 cursor-pointer"  onClick={()=> setOpen(!open)}>
                <b className={`w-full text-base ${ open ? 'font-normal' : 'font-bold'}`}>{ title }</b>
                <img alt="" v-if="!collapse" className={`ml-3 cursor-pointer w-[15px] ${ open ? 'up_arrow' : 'down_arrow'}`}
                    src={ChevronDown} 
                    />
            </p>
            <div className={`mt-2 article_container ${open ? 'max-h-0' : 'max-h-screen'}`} >
                { children }
            </div>
        </div >
    )
}

export default FaqCollapse
