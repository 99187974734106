import React, { useEffect, useRef, useState } from "react";
import TopNav from "components/app/TopNav";
import HeroTop from "components/app/HeroTop";
import MidHero from "components/app/MidHero";
import FeaturesComponent from "components/app/Features";
import ContactUs from "components/app/ContactUs";
import Testimonia from "components/app/Testimonia";
import Faq from "components/app/Faq";
import ConnectUs from "components/app/ConnectUs";
import Footer from "components/app/Footer"
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import { useScroll } from '@react-spring/web'
import ComingSoon from "components/app/ComingSoonsection";



interface Props { }

const Dashboard: React.FC<Props> = (props: Props) => {
  const midHeroRef = useRef<HTMLDivElement | null>(null);
  const featuresRef = useRef<HTMLDivElement | null>(null)
  const faqRef = useRef<HTMLDivElement | null>(null)
  const contactRef = useRef<HTMLDivElement | null>(null)
  const homeRef = useRef<HTMLDivElement | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [navOpacity, setNavOpacity] = useState(0)

  useScroll({
    container: containerRef.current as any,
    onChange: ({ value: { scrollYProgress } }) => {
      setNavOpacity(scrollYProgress)
    },
    default: {
      immediate: true,
    },
  })

  const scrollToElement = (node: any) => {
    scrollIntoView(node, {
      behavior: 'smooth',
      scrollMode: 'if-needed',
      block: 'start',
    });
  }

  useEffect(() => {
    // const cursor = document.querySelector('.cursor')

    // for (let i = 0; i < $hoverables.length; i++) {
    //   $hoverables[i].addEventListener("mouseenter", onMouseHover);
    //   $hoverables[i].addEventListener("mouseleave", onMouseHoverOut);
    //   $hoverables[i].addEventListener("mousemove", onMouseHover);
    // }

    // function onMouseHover() {
    //   gsap.to(cursor, 0.3, {
    //     scale: 3,
    //     top: 20,
    //     left: 20
    //   });
    // }
    // function onMouseHoverOut() {
    //   gsap.to(cursor, 0.3, {
    //     scale: 0.5,
    //     top: 0,
    //     left: 0
    //   });
    // }

    // const handleMouseMove = (e: MouseEvent) => {
    //     gsap.to(cursor, {
    //         duration: 0.4,
    //         x: e.pageX , // Adjusting cursor position to center the cursor
    //         y: e.pageY - 25
    //     })
    // };

    // document.body.addEventListener('mousemove', handleMouseMove)
    // return () => {
    //     document.body.removeEventListener('mousemove', handleMouseMove);
    // };
  }, [])


  const moveToSections = (value: string) => {
    switch (value) {
      case 'about_us':
        scrollToElement(midHeroRef.current)
        break;
      case 'features':
        scrollToElement(featuresRef.current)
        break;
      case 'faq':
        scrollToElement(faqRef.current)
        break;
      case 'contact_us':
        scrollToElement(contactRef.current)
        break;
      default:
        scrollToElement(homeRef.current)
        break;
    }
  }

  return (
    <div ref={containerRef} id="container">
      {/* <div className="cursor" >
                            <svg height="30" width="30">
                                <circle cx="15" cy="15" r="12" stroke-width="0" ></circle>
                            </svg>
                        </div> */}
      <TopNav moveToSections={moveToSections} navOpacity={navOpacity} />
      <div className="flex justify-center" ref={homeRef}>
        <div className="hero_top_bg sm:pt-10 w-full">
          <div className="w-full"> <HeroTop /></div>
        </div>
      </div>
      <div className="flex justify-center">
        <div className="w-full">
          <div ref={midHeroRef}><MidHero /></div>
          <div ref={featuresRef}><FeaturesComponent /></div>
          
          <div className="relative">
            <div ref={contactRef}> <ContactUs /></div>
            <ComingSoon/>
            <Testimonia />
          </div>
   
          <div ref={faqRef}><Faq /></div>
          <ConnectUs />
          <hr className="divider" />
          <Footer moveToSections={moveToSections} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
