import { ReactNode, useRef } from 'react'
import { gsap } from "gsap";

interface Props {
    children: ReactNode,
    defaultValue?: boolean
    className?: string
}

function Tilt(props: Props) {
    const { children, className } = props
    const divRef = useRef(null);
    const childRef = useRef(null);

    // const maxRot = 30;
    // const mouseMoveFunc=(evt: any)=> {
    //     //@ts-ignore
    //     const maxX = gsap.getProperty(childRef.current, "width") * 0.75;

    //     const percent = gsap.utils.normalize(0, (childRef.current as any).width, evt.pageX);
    //    console.log(maxX, percent)
    //     gsap.to(childRef.current, {
    //         duration: 0.2,
    //         x: percent * maxX - maxX / 2,
    //         rotationY: -(percent * maxRot - maxRot / 2),
    //         overwrite: true
    //     });
    // }

    const handleMouseMove = (event: { clientX: number; clientY: number }) => {
        if (divRef.current) {
            // Get the bounding rectangle of the div
            const rect = (divRef.current as any).getBoundingClientRect();

            // Calculate mouse position relative to the div
            const x = event.clientX - rect.left;
            const y = event.clientY - rect.top;
            const hx = x / 2
            const hy = y / 2
            const hw = rect.width / 2;
            const hh = rect.height / 2
           
            // Update margins based on mouse position
            let marginLeft = 0;
            let marginTop = 0;

            if (x < hw) {
                marginLeft = 80 * (x / rect.width);
            } else {
                marginLeft = 40 - (80 - (70 * ((hw - hx) / hx)));
            }

            if (y < hh) {
                marginTop = 80 * (y / rect.height);
            } else {
                marginTop = 40 - (80 - (80 * ((hh - hy) / hy)));
            }
            // setPosition({ marginLeft, marginTop });
            // Apply calculated margins
                gsap.to(childRef.current, 0.4, {
                    x: -(marginLeft),
                    y: -(marginTop),
                    overwrite: true
                });
    
            return () => {
                (divRef.current as any).removeEventListener('mousemove', handleMouseMove);
            };
        }
    };

    const handleMouseLeave = (event: { clientX: number; clientY: number }) => {
        gsap.to(childRef.current, 0.3, {
            x: 0,
            y: 0,
            overwrite: true
        });
    }

    return (
        <div ref={divRef} className={`${className} px-0 py-0 `} onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        >
            <div ref={childRef} className='smooth_trans opacity-40'>
                {children}
            </div>
            {/* <span>Mouse X: {parseInt(String(position.marginLeft))}</span><br />
            <span>Mouse Y: {parseInt(String(position.marginTop))}</span> */}
        </div>
    )
}

export default Tilt
