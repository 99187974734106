import stacks from './stack';
import routes from './routes';

import Dashboard from '../pages/Dashboard';


const route = [
    // Landing Pages
    {
        stack:stacks.LANDING,
        route: routes.landing,
        Page: Dashboard,
    },
]


export default route

